var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"my_list_page wallet_page",attrs:{"id":"contents"}},[_c('div',{staticClass:"set_inner clearFix"},[_c('h1',{staticClass:"page_ttl only_pc"},[_vm._v("My Account")]),_c('h1',{staticClass:"page_ttl dv_tab"},[_vm._v("My Wallet")]),_c('AccountMenu'),_c('div',{staticClass:"min_inner"},[_c('section',{staticClass:"wallet_sec"},[(_vm.ethInfo.walletYn === 'Y' || _vm.hdrInfo.walletYn === 'Y')?_c('div',{staticClass:"wallet_title"},[_c('h2',[_vm._v("My Wallet")]),_vm._m(0)]):_c('div',{staticClass:"wallet_connect_title"},[_c('h2',[_vm._v("Connect Your Wallet")]),_vm._m(1)]),(_vm.ethInfo.walletYn == 'Y')?_c('div',{staticClass:"wallet_item_wrap"},[_c('div',{staticClass:"wallet_item_head"},[_c('div',{staticClass:"wallet_info_group"},[_c('div',{staticClass:"img_area"},[_c('picture',[_c('img',{attrs:{"src":require('@/assets/img/img_wallet_eth.svg'),"alt":"ethereumWallet"}})])]),_c('div',{staticClass:"name_area"},[_vm._v("Ethereum")]),_c('div',{staticClass:"btn_area"},[_c('button',{staticClass:"btn h_m strong disconnect",class:{
                    is_loading: _vm.loading_eth,
                  },on:{"click":function($event){return _vm.callDisconnect(_vm.ethInfo.bcNwTp)}}},[_vm._v(" Disconnect ")])])])]),_c('div',{staticClass:"wallet_item_body"},[_c('div',{staticClass:"money_area"},[_c('dl',[_c('dt',[_vm._v("Balance")]),_c('dd',[_vm._v(" "+_vm._s(_vm.ethereumBalance)+" "),_c('span',{staticClass:"unit"},[_vm._v("ETH")])])])]),_c('div',{staticClass:"account_area"},[_c('dl',[_c('dt',[_vm._v("Account")]),_c('dd',[_vm._v(_vm._s(_vm.addrEllipsis(_vm.ethInfo.bc_account)))])])])])]):_c('div',{staticClass:"wallet_item_wrap"},[(_vm.loading_eth)?_c('div',{staticClass:"wallet_list_group"},[_c('LoadingSpinner')],1):_c('div',{staticClass:"wallet_list_group"},[_c('div',{staticClass:"img_area"},[_c('picture',[_c('img',{attrs:{"src":require('@/assets/img/img_wallet_eth.svg'),"alt":"ethereumWallet"}})])]),_vm._m(2),_c('div',{staticClass:"btn_area"},[_c('button',{staticClass:"btn h_l strong connect",on:{"click":function($event){return _vm.open('eth')}}},[_vm._v(" Connect Wallet ")])])])]),(_vm.hdrInfo.walletYn == 'Y')?_c('div',{staticClass:"wallet_item_wrap"},[_c('div',{staticClass:"wallet_item_head"},[_c('div',{staticClass:"wallet_info_group"},[_c('div',{staticClass:"img_area"},[_c('picture',[_c('img',{attrs:{"width":"70","height":"76","src":require('@/assets/img/img_wallet_hedera.svg'),"alt":"hederaWallet"}})])]),_vm._m(3),_c('div',{staticClass:"btn_area"},[_c('button',{staticClass:"btn h_m strong disconnect",class:{
                    is_loading: _vm.loading_hdr,
                  },on:{"click":function($event){return _vm.callDisconnect(_vm.hdrInfo.bcNwTp)}}},[_vm._v(" Disconnect ")])])])]),_c('div',{staticClass:"wallet_item_body"},[_c('div',{staticClass:"money_area"},[_c('dl',[_c('dt',[_vm._v("Balance")]),(_vm.usdcBalance)?_c('dd',[_vm._v(" "+_vm._s(_vm.usdcBalance)+" "),_c('span',{staticClass:"unit"},[_vm._v("USDC")])]):_c('dd',[_c('Skeleton')],1)])]),_c('div',{staticClass:"account_area"},[_c('dl',[_c('dt',[_vm._v("Account")]),_c('dd',[_vm._v(_vm._s(_vm.hdrInfo.bc_account))])])])])]):_c('div',{staticClass:"wallet_item_wrap"},[(_vm.loading_hdr)?_c('div',{staticClass:"wallet_list_group"},[_c('LoadingSpinner')],1):_c('div',{staticClass:"wallet_list_group"},[_c('div',{staticClass:"img_area"},[_c('picture',[_c('img',{attrs:{"src":require('@/assets/img/img_wallet_hedera.svg'),"alt":"hederaWallet"}})])]),_vm._m(4),_c('div',{staticClass:"btn_area"},[_c('button',{staticClass:"btn h_l strong connect",on:{"click":function($event){return _vm.open('hedera')}}},[_vm._v(" Connect Wallet ")])])])])])])],1),_c('WalletQrModal',{attrs:{"showWallet":_vm.showWallet,"modal-data":_vm.ChangeModal},on:{"close":_vm.close}}),_c('ChangeWalletModal',{attrs:{"showChangeWallet":_vm.showChangeWallet},on:{"close":_vm.close}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('em',{staticClass:"c_point"},[_vm._v("Manage your wallet")]),_vm._v(" accounts and check balances. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Connect a wallet to purchase "),_c('em',{staticClass:"c_point"},[_vm._v("NFTs from LG Art Lab ")]),_c('br',{staticClass:"dv_pc"}),_vm._v("Choose and press the button you’d like to connect a wallet. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name_area"},[_c('p',[_vm._v("Ethereum")]),_c('span',[_vm._v("Connect your Ethereum wallet to use LG Art Lab service")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name_area"},[_c('p',[_vm._v("Hedera")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name_area"},[_c('p',[_vm._v("Hedera")]),_c('span',[_vm._v("Connect your Hedera wallet to use LG Art Lab service")])])
}]

export { render, staticRenderFns }