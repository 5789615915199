<template>
  <main id="contents" class="market_page detail_page">
    <h1 class="page_ttl is_blind">Marketplace Detail</h1>
    <section class="overview_sec">
      <div
        class="bg_el"
        :style="`background-image: url(${artworkBackground})`"
      ></div>
      <div class="set_inner">
        <header class="sec_head">
          <div class="thumb_area">
            <img
              v-if="editionInfos.mimeType === 'image/jpeg'"
              :srcset="`${source_small} 590w, ${source_medium} 1920w, ${source}`"
              :src="source"
              width="890"
              height="500"
              :alt="artworkAlt ? artworkAlt : ''"
            />
            <video
              v-else
              loop
              autoplay
              playsinline
              muted
              style="width: auto"
              :src="source"
            >
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
          <button
            class="btn type_ic btn_like"
            type="button"
            :class="{
              is_active: likeStatus === true,
            }"
            @click="likeThis(editionInfos)"
          >
            <IcLike
              v-if="likeStatus === true"
              :status="likeStatus === true"
              icon-color="#ff521d"
              ref="icLike"
            />
            <IcLike v-else icon-color="#fff" ref="icLike" />
          </button>
        </header>
        <!-- TIP:  -->
        <div
          class="sec_body"
          v-if="Object.keys(editionInfos).length > 0"
          v-cloak
        >
          <div class="ttl_wrap">
            <div class="ttl_area">
              <h3
                :class="[
                  totalEdition > 1 && isMultiEdition && editionId != 0
                    ? 'ttl clickable textUnderLine'
                    : 'ttl',
                ]"
                @click="goMultiEdition"
              >
                {{ editionInfos.title }}
              </h3>
            </div>
            <!-- {{ editionInfos.artistInfo.avatarInfo[0].resUrl }} -->
            <div class="profile_info is_art clickable" style="display: flex">
              <span
                v-if="artistAvatarImg"
                class="profile"
                :style="`background-image: url(${artistAvatarImg})`"
              ></span>
              <IcProfile v-else class="profile" />
              <IcRing class="ic_ring" @click="goArtistProfile()" />
              <span class="name" @click="goArtistProfile()">{{
                artistName
              }}</span>
            </div>
            <p class="edit" v-if="editionId != 0">
              {{
                editionInfos.totalCount > 1
                  ? `${editionInfos.editionId} of ${editionInfos.totalCount} Editions`
                  : `${editionInfos.editionId} edition`
              }}
            </p>
            <p class="edit" v-else>
              {{
                editionInfos.totalEdition > 1
                  ? `${editionInfos.totalEdition} Editions`
                  : editionInfos.editionId === 0
                  ? '1 edition'
                  : `${editionInfos.editionId} edition`
              }}
            </p>
            <!-- s: DEV 221207 추가  -->

            <template
              v-if="
                editionInfos.bcNwTp == '10' &&
                hdrWallet.walletYn === 'N' &&
                isMob &&
                (openBlade || openWallypto)
              "
            >
              <div class="input_area select drop_ui">
                <div class="input_box drop_box">
                  <button
                    class="drop_btn has_arw"
                    :class="{ is_active: walletSelect }"
                    role="combobox"
                    aria-haspopup="listbox"
                    aria-controls="dropWalletOpts"
                    :aria-activedescendant="`wallet_op_${walletFocusOpt}`"
                    :aria-expanded="walletSelect ? 'true' : 'false'"
                    @click="walletSelect = !walletSelect"
                  >
                    {{ walletOpt.label }}
                    <b v-if="walletOpt.value === 'WLT001'" class="badge"
                      >Popular</b
                    >
                    <ArwSelect aria-hidden="true" />
                  </button>
                  <transition name="fade">
                    <ul v-if="walletSelect" class="drop_op_list" role="listbox">
                      <li
                        v-for="(opt, i) in walletOptData"
                        :key="'wallet_op_' + i"
                        :id="'wallet_op_' + i"
                        role="option"
                        aria-selected="false"
                        :class="[
                          { is_focus: walletFocusOpt === i },
                          { is_active: walletOpt.value === opt.value },
                        ]"
                        @click="walletOtp(opt, i)"
                      >
                        <span>{{ opt.label }}</span>
                        <b v-if="opt.value === 'WLT001'" class="badge"
                          >Popular</b
                        >
                      </li>
                    </ul>
                  </transition>
                </div>
              </div>
            </template>
            <!-- e: DEV 221207 추가  -->
          </div>
          <div class="buy_wrap float_el">
            <div class="buy_group">
              <div class="label_list_group">
                <dl class="label_list" v-if="editionId != 0">
                  <dt>
                    Contract <br class="dv_mob" />
                    Address
                  </dt>
                  <dd>
                    <button
                      class="tooltip_ui"
                      v-if="contractAddress"
                      @click="goContract"
                    >
                      <b class="clickable">{{
                        contractAddress.length > 15
                          ? contractAddress.substr(0, 8) +
                            '...' +
                            contractAddress.substr(
                              contractAddress.length - 3,
                              contractAddress.length,
                            )
                          : contractAddress
                      }}</b>
                      <div
                        class="tooltip_area"
                        role="tooltip"
                        ref="contractAddress"
                      >
                        {{ contractAddress }}
                      </div>
                    </button>
                    <button class="btn type_ic btn_copy" @click="copy">
                      <IcCopy />
                    </button>
                  </dd>
                </dl>

                <dl v-if="editionId > 0" class="label_list own">
                  <dt>Owned by</dt>
                  <dd>
                    <div
                      class="profile_info"
                      :class="{
                        clickable: editionInfos.ownerName.indexOf('0x') == -1,
                      }"
                    >
                      <span
                        v-if="
                          editionInfos.ownerAvatarInfo &&
                          editionInfos.ownerAvatarInfo.length
                        "
                        class="profile"
                        :style="`background-image: url(${editionInfos.ownerAvatarInfo[0].resUrl})`"
                      ></span>
                      <IcProfile
                        v-else
                        :width="32"
                        :height="32"
                        :stroke-width="0.9"
                        class="profile"
                      />
                      <button
                        @click="goUserProfile()"
                        v-if="editionInfos.bcNwtp == '10'"
                      >
                        <b class="name">{{ editionInfos.ownerName }}</b>
                      </button>
                      <button
                        @click="
                          goUserProfile(
                            editionInfos.ownerName.indexOf('0x') !== -1
                              ? true
                              : false,
                          )
                        "
                        :class="{
                          ethName: editionInfos.ownerName.indexOf('0x') !== -1,
                        }"
                        v-else
                      >
                        <b class="name">{{
                          editionInfos.ownerName.indexOf('0x') !== -1
                            ? editionInfos.ownerName.length > 15
                              ? editionInfos.ownerName.substr(0, 8) +
                                '...' +
                                editionInfos.ownerName.substr(
                                  editionInfos.ownerName.length - 3,
                                  editionInfos.ownerName.length,
                                )
                              : editionInfos.ownerName
                            : editionInfos.ownerName
                        }}</b>
                      </button>
                    </div>
                  </dd>
                </dl>

                <dl class="label_list">
                  <dt>Last Sale</dt>
                  <dd>
                    <p class="price">
                      <b v-if="editionInfos.bcNwTp == '10'"
                        >{{ editionInfos.currentPrice | setNum }} USDC</b
                      >
                      <b
                        v-else-if="
                          editionInfos.bcNwTp == '20' &&
                          editionInfos.currentPrice == 0 &&
                          editionInfos.currentPriceToEthUsd == 0
                        "
                        >-</b
                      >
                      <b
                        v-else-if="
                          editionInfos.bcNwTp == '20' &&
                          editionInfos.currentPrice == 0 &&
                          editionInfos.currentPriceToEthUsd > 0
                        "
                      >
                        &lt; 0.001<span class="unit">Ξ</span>
                        <span class="dollars"
                          >({{
                            editionInfos.currentPriceToEthUsd | crtpyAbbreviate
                          }})</span
                        >
                      </b>
                      <b v-else>
                        {{ editionInfos.currentPrice }}
                        <span class="unit">Ξ</span>
                        <span class="dollars"
                          >({{
                            editionInfos.currentPriceToEthUsd | crtpyAbbreviate
                          }})</span
                        ></b
                      >
                    </p>
                    <small
                      >{{ editionInfos.lastSaleDate | GmtToTz | UsaFormat }}
                    </small>
                  </dd>
                </dl>
                <dl class="label_list">
                  <dt>Blockchains</dt>
                  <dd>
                    <!-- WIP : 아이콘 분기 -->
                    <p class="blockchains">
                      <template v-if="editionInfos.bcNwTp == '10'">
                        <IcHederaS />
                        <b>Hedera</b>
                      </template>
                      <template v-else>
                        <IcEthS />
                        <b>Ethereum</b>
                      </template>
                    </p>
                  </dd>
                </dl>
                <!-- PUB : 블록체인 아이콘 추가 -->
                <dl
                  class="label_list"
                  v-if="showPriceTax && editionInfos.totalEdition > 1"
                >
                  <dt>
                    {{ editionInfos.bcNwTp == '10' ? 'Lowest Price' : 'Price' }}
                  </dt>
                  <dd>
                    <p class="price">
                      <!-- PUB : 블록체인 금액표기 수정 -->
                      <!-- WIP : 금액표기 분기 -->
                      <b v-if="editionInfos.bcNwTp == '10'">
                        {{ editionInfos.lowestPrice | setNum }} USDC
                      </b>
                      <b v-else>
                        {{ editionInfos.price }}<span class="unit">Ξ</span
                        ><span class="dollars"
                          >({{
                            editionInfos.priceToEthUsd | crtpyAbbreviate
                          }})</span
                        >
                      </b>
                    </p>
                  </dd>
                </dl>
                <dl class="label_list" v-if="showPriceTax">
                  <dt>Estimated <br class="dv_mob" />Tax</dt>
                  <dd class="">
                    <p class="price">
                      <!-- PUB : 블록체인 금액표기 수정 -->
                      <!-- WIP : 금액표기 분기 origin code 안에 publish 내용 적용 -->
                      <template v-if="accessToken && zipCodeCheck">
                        <b v-if="editionInfos.bcNwTp == '10'"
                          >{{ taxOnly | setNum }} USDC</b
                        >
                        <b v-else
                          >{{ taxOnly }}<span class="unit">Ξ</span
                          ><span class="dollars"
                            >({{
                              editionInfos.taxToEthUsd | crtpyAbbreviate
                            }})</span
                          ></b
                        >
                      </template>
                      <template v-else-if="accessToken && !zipCodeCheck">
                        <b
                          ><a
                            href="javascript:;"
                            class="c_point"
                            @click="directUpdateInfo"
                            >Update your info</a
                          >
                          to check the sales tax estimate.</b
                        >
                      </template>
                      <template v-else-if="!accessToken">
                        <b
                          ><a
                            href="javascript:;"
                            class="c_point"
                            @click="loginPopup()"
                            >Sign in</a
                          >
                          to check the sales tax estimate.</b
                        >
                      </template>
                    </p>
                  </dd>
                </dl>
              </div>
              <!-- 
              판매방식 설정 

              salesMethod 
              3: 'makeoffer', 
              2: 'listprice', 
              1: 'notforsale'  
              -->
              <template v-if="isMob || editionInfos.bcNwTp == '20'">
                <button
                  v-if="totalEdition == 1 || (isMultiEdition && editionId != 0)"
                  class="btn w_f strong h_l"
                  :class="{ isDeactive: isLoading }"
                  @click="purchaseEvt(editionInfos, isMine ? 'changeType' : '')"
                  :disabled="
                    !editionInfos.salesMethod || editionInfos.salesMethod === 1
                      ? isMine
                        ? false
                        : true
                      : false
                  "
                >
                  <p v-if="isMine">
                    {{
                      !editionInfos.salesMethod ||
                      editionInfos.salesMethod === 1
                        ? 'Re-sell my collection'
                        : 'Stop re-selling my collection'
                    }}
                  </p>
                  <p v-else>
                    <template v-if="editionInfos.bcNwTp == '20'">
                      {{
                        !editionInfos.salesMethod ||
                        editionInfos.salesMethod === 1
                          ? 'Not for Sale'
                          : !this.accessToken
                          ? 'Sign In to Purchase'
                          : this.ethWallet.walletYn === 'N' ||
                            !getWeb3ModalStatus.address
                          ? 'Connect Wallet'
                          : 'Purchase'
                      }}
                    </template>
                    <template v-else>
                      {{
                        !editionInfos.salesMethod ||
                        editionInfos.salesMethod === 1
                          ? 'Not for Sale'
                          : !this.accessToken
                          ? 'Sign In to Purchase'
                          : this.hdrWallet.walletYn === 'N'
                          ? 'Connect Wallet to Purchase'
                          : !this.zipCodeCheck
                          ? 'Purchase(USDC Hedera Only)'
                          : 'Purchase'
                      }}
                    </template>
                  </p>
                </button>
              </template>
              <template v-else>
                <button
                  v-if="totalEdition == 1 || (isMultiEdition && editionId != 0)"
                  class="btn w_f strong h_l"
                  @click="purchaseEvt(editionInfos, isMine ? 'changeType' : '')"
                  :disabled="
                    !editionInfos.salesMethod || editionInfos.salesMethod === 1
                  "
                >
                  {{
                    !editionInfos.salesMethod || editionInfos.salesMethod === 1
                      ? 'Not for sale'
                      : this.isMine === true
                      ? 'Stop re-selling my collection'
                      : 'Purchase'
                  }}
                </button>
              </template>
            </div>
          </div>
          <div class="desc_wrap">
            <div class="desc_group more_ui" :class="{ is_more: showMoreDesc }">
              <h4 class="ttl type_underL">Description</h4>
              <p class="desc" v-if="editionInfos.editionContent">
                {{ editionInfos.editionContent }}
              </p>
              <button
                class="btn type_txt has_arw dv_mob toggle_desc"
                @click="showMoreDesc = !showMoreDesc"
              >
                {{ showMoreDesc ? 'Close' : 'More' }}
              </button>
            </div>
            <div class="tag_group">
              <h4 class="ttl type_underL">Tag</h4>
              <ul class="tag_list clearFix">
                <li
                  v-for="(tag, i) in editionInfos.keywords"
                  :key="'tag_' + i"
                  class="tag_node"
                >
                  <button class="tag_item" @click="goTagSearch(tag)">
                    <span>{{ tag }}</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="set_inner">
      <section class="detail_sec detail_info_sec">
        <!-- Dev: 220413 추가 - ref 추가 : 스크롤 시 상단 고정 -->
        <header ref="tabs" class="sec_head">
          <div class="tab_wrap">
            <!-- Dev: tab이 하나일 경우 type_stack 클래스 바인드 -->
            <div class="tab_list" role="tablist">
              <template v-for="tab in infoTabData">
                <!-- 1개 에디션의 경우 = status-->
                <div
                  v-if="editionId > 0 && tab.id == 'status'"
                  :key="tab.id"
                  class="tab_node"
                  role="presentation"
                >
                  <button
                    :id="'tab_' + tab.id"
                    class="tab_item"
                    role="tab"
                    :class="{ is_active: currentTab === tab.id }"
                    @click="setTab(tab.id)"
                    :aria-controls="tab.id + '_panel'"
                    :aria-selected="tab.id === currentTab ? true : false"
                  >
                    <IcTime v-if="tab.icon === 'time'" stroke-color="#fff" />
                    <IcItems v-if="tab.icon === 'items'" stroke-color="#fff" />
                    <IcEye v-if="tab.icon === 'eye'" stroke-color="#fff" />
                    <IcVideo v-if="tab.icon === 'video'" stroke-color="#fff" />
                    <span>{{ tab.name }}</span>
                  </button>
                </div>
                <div
                  v-if="editionId === 0 && tab.id == 'editions'"
                  :key="tab.id"
                  class="tab_node"
                  role="presentation"
                >
                  <button
                    :id="'tab_' + tab.id"
                    class="tab_item"
                    role="tab"
                    :class="{ is_active: currentTab === tab.id }"
                    @click="setTab(tab.id)"
                    :aria-controls="tab.id + '_panel'"
                    :aria-selected="tab.id === currentTab ? true : false"
                  >
                    <IcTime v-if="tab.icon === 'time'" stroke-color="#fff" />
                    <IcItems v-if="tab.icon === 'items'" stroke-color="#fff" />
                    <IcEye v-if="tab.icon === 'eye'" stroke-color="#fff" />
                    <IcVideo v-if="tab.icon === 'video'" stroke-color="#fff" />
                    <span>{{ tab.name }}</span>
                  </button>
                </div>

                <div
                  v-if="tab.id == 'film' || tab.id == 'docent'"
                  :key="tab.id"
                  class="tab_node"
                  role="presentation"
                >
                  <button
                    :id="'tab_' + tab.id"
                    class="tab_item"
                    role="tab"
                    :class="{ is_active: currentTab === tab.id }"
                    @click="setTab(tab.id)"
                    :aria-controls="tab.id + '_panel'"
                    :aria-selected="tab.id === currentTab ? true : false"
                  >
                    <IcTime v-if="tab.icon === 'time'" stroke-color="#fff" />
                    <IcItems v-if="tab.icon === 'items'" stroke-color="#fff" />
                    <IcEye v-if="tab.icon === 'eye'" stroke-color="#fff" />
                    <IcVideo v-if="tab.icon === 'video'" stroke-color="#fff" />
                    <span>{{ tab.name }}</span>
                  </button>
                </div>
              </template>
            </div>
          </div>
        </header>
        <div
          :id="currentTab + '_panel'"
          class="sec_body tab_panel"
          role="tabpanel"
          :aria-labelledby="'tab_' + currentTab"
        >
          <template v-if="editionId > 0 && currentTab === 'status'">
            <div class="detail_info_wrap">
              <div class="detail_info_inner">
                <header class="detail_info_head">
                  <!-- <p class="info_txt refresh">
                    <IcRefreshS stroke-color="var(--txt-gray-2)" />
                    <span>Refresh in 2 minutes</span>
                  </p> -->
                  <ul class="status_list_head">
                    <li class="history_info">Status</li>
                    <li class="date_info">Date and Time</li>
                    <li class="market_info">
                      <span v-if="editionInfos.bcNwTp == '20'">Market</span>
                    </li>
                    <li class="price_info">Price</li>
                  </ul>
                  <!-- PUB : 리프레시 인포 삭제/ 리스트 헤더 추가 -->
                </header>

                <div class="detail_info_body" v-if="isStatusLoading">
                  <LoadingSpinner />
                </div>
                <div class="detail_info_body" v-else>
                  <ul
                    v-if="statusInfos && statusInfos.length > 0"
                    class="status_list"
                  >
                    <li
                      v-for="(status, i) in statusInfos"
                      :key="'status_' + i"
                      class="status_node"
                    >
                      <div href="#" class="status_item">
                        <div class="history_info">
                          <span
                            >{{ status.action }}
                            <em
                              class="name"
                              v-if="status.actionType == 'normal'"
                            >
                              {{ status.activityName }}
                            </em></span
                          >
                        </div>
                        <div class="date_info">
                          <p class="date">
                            {{ status.date | GmtToTz | UsaFormat }}
                          </p>
                        </div>
                        <div class="market_info">
                          <!-- <span v-if="status.bcNwTp == '20'">Other Market</span> -->
                          <span v-if="status.bcNwTp == '20'">{{
                            status.otherMarketYn == 'Y'
                              ? 'Other Market'
                              : 'LG Art Lab'
                          }}</span>
                        </div>
                        <!-- PUB : 금액표기 수정 (b태그 상위에 p태그 추가) -->
                        <div class="price_info">
                          <p class="price">
                            <!-- WIP : 금액표기 분기-->
                            <b v-if="status.bcNwTp == '10'"
                              >{{ status.price | setNum }} USDC</b
                            >
                            <b
                              v-else-if="
                                status.bcNwTp == '20' &&
                                status.action.indexOf('Stopped') !== -1
                              "
                              >-</b
                            >
                            <b
                              v-else-if="
                                status.bcNwTp == '20' &&
                                status.price == 0 &&
                                status.priceToEthUsd == 0
                              "
                              >-</b
                            >
                            <b
                              v-else-if="
                                status.bcNwTp == '20' &&
                                status.price == 0 &&
                                status.priceToEthUsd > 0
                              "
                            >
                              &lt; 0.001
                              <span class="unit">Ξ</span>
                              <span class="dollars"
                                >({{
                                  status.priceToEthUsd | crtpyAbbreviate
                                }})</span
                              >
                            </b>
                            <b v-else
                              >{{ status.price }}<span class="unit">Ξ</span
                              ><span class="dollars"
                                >({{
                                  status.priceToEthUsd | crtpyAbbreviate
                                }})</span
                              ></b
                            >
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div v-else class="guide_ui no_data">
                    <div class="img_area">
                      <picture>
                        <source
                          media="(max-width: 600px)"
                          type="image/png"
                          :srcset="`${require('@/assets/img/img_mob_illust_nodata_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nodata_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nodata.png')}`"
                        />
                        <img
                          :src="require('@/assets/img/img_illust_nodata.png')"
                          alt="nodata"
                        />
                      </picture>
                    </div>
                    <p>
                      This artwork currently doesn't have any sales activity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="editionId === 0 && currentTab === 'editions'">
            <div class="list_wrap tbl_wrap detail_info_wrap">
              <header
                class="list_head tbl_head detail_info_head taR"
                ref="ulRef"
              >
                <!-- PUB : 리프레시 인포 삭제-->
                <!-- <p class="info_txt refresh">
                  <IcRefreshS stroke-color="var(--txt-gray-2)" />
                  <span>Refresh in 2 minutes</span>
                </p> -->
              </header>
              <div class="list_body tbl_body detail_info_body">
                <div class="tbl_area">
                  <table
                    class="tbl"
                    :class="{
                      isLoading: isStatusLoading,
                    }"
                  >
                    <caption class="is_blind">
                      Editions
                    </caption>
                    <thead>
                      <tr>
                        <th class="item">Editions</th>
                        <th class="own">Owned by</th>
                        <th class="price">Last Sale Price</th>
                        <th class="time">Last Sale Time</th>
                        <th class="current">Current Price</th>
                        <th class="button"></th>
                      </tr>
                    </thead>
                    <tbody v-if="isStatusLoading" colspan="6">
                      <LoadingSpinner />
                    </tbody>
                    <tbody v-else>
                      <tr v-for="(item, i) in editionItems" :key="item.id">
                        <td class="item">
                          <div class="thumb_area clickable">
                            <img
                              v-if="item.mimeType === 'video/mp4'"
                              :srcset="
                                !item.videoThumbInfos
                                  ? null
                                  : `${item.videoThumbInfos[0].resUrl} 2x, ${item.videoThumbInfos[1].resUrl}`
                              "
                              :src="
                                !item.videoThumbInfos
                                  ? null
                                  : `${item.videoThumbInfos[1].resUrl}`
                              "
                              :width="179"
                              :height="100"
                              :alt="item.editionId"
                              @click="
                                goArtworkDetail(item.artworkId, item.editionId)
                              "
                            />

                            <img
                              v-else
                              :srcset="
                                !item.videoThumbInfos
                                  ? null
                                  : `${item.imageInfos[0].resUrl} 2x, ${item.imageInfos[1].resUrl}`
                              "
                              :src="
                                !item.imageInfos
                                  ? null
                                  : `${item.imageInfos[1].resUrl}`
                              "
                              :width="179"
                              :height="100"
                              :alt="item.editionId"
                              @click="
                                goArtworkDetail(item.artworkId, item.editionId)
                              "
                            />
                            <button
                              class="btn type_ic btn_like"
                              type="button"
                              :class="{
                                is_active: editionItems[i].favorites == 'Y',
                              }"
                              @click="
                                likeThisEdition(
                                  item.artworkId,
                                  item.editionId,
                                  i,
                                )
                              "
                            >
                              <IcLike
                                v-if="editionItems[i].favorites == 'N'"
                                :status="editionItems[i].favorites == 'N'"
                                icon-color="#fff"
                                ref="icLikeEdition"
                              />
                              <IcLike
                                v-else
                                icon-color="#ff521d"
                                ref="icLikeEdition"
                              />
                            </button>
                          </div>
                          <div class="label_info edit" data-label="Editions">
                            <b>#{{ item.editionId }}</b> /
                            {{ editionInfos.totalCount }}
                          </div>
                        </td>
                        <td class="own label_info" data-label="Owned by">
                          <b>{{ item.name }}</b>
                        </td>
                        <td class="price label_info" data-label="Last Sale">
                          <b v-if="item.bcNwTp == '10'">
                            {{ item.lastSalePrice | setNum }}<br />USDC
                          </b>
                          <b v-else
                            >{{ item.lastSalePrice
                            }}<span class="unit">Ξ<br /></span
                            ><span class="dollars"
                              >({{
                                item.lastSaleToEthUsd | crtpyAbbreviate
                              }})</span
                            ></b
                          >
                          <small class="dv_mob" style="white-space: pre-line"
                            >{{
                              item.lastSaleDate | GmtToTz | UsaFormat('newLine')
                            }}
                          </small>
                        </td>
                        <td class="time dv_pc" :title="item.ownrWlltAcnt">
                          <span style="white-space: pre-line">
                            {{
                              item.lastSaleDate | GmtToTz | UsaFormat('newLine')
                            }}</span
                          >
                        </td>
                        <td
                          class="current label_info"
                          data-label="Current Price"
                        >
                          <b v-if="item.bcNwTp == '10'"
                            >{{ item.price | setNum }} <br />USDC</b
                          >
                          <b
                            v-else-if="
                              item.bcNwTp == '20' &&
                              item.price == 0 &&
                              item.priceToEthUsd == 0
                            "
                          >
                            -</b
                          >
                          <b
                            v-else-if="
                              item.bcNwTp == '20' &&
                              item.salesMethod &&
                              item.salesMethod === 1
                            "
                          >
                            -</b
                          >
                          <b
                            v-else-if="
                              item.bcNwTp == '20' &&
                              item.price == 0 &&
                              item.priceToEthUsd > 0
                            "
                          >
                            &lt; 0.001
                            <span class="unit">Ξ</span>
                            <span class="dollars"
                              >({{
                                item.priceToEthUsd | crtpyAbbreviate
                              }})</span
                            >
                          </b>
                          <b v-else>
                            {{ item.price }}
                            <span class="unit">Ξ</span><br />
                            <span class="dollars"
                              >({{
                                item.priceToEthUsd | crtpyAbbreviate
                              }})</span
                            ></b
                          >
                        </td>
                        <td class="button">
                          <button
                            v-if="checkOwner(item)"
                            @click="purchaseEvt(item, 'changeType')"
                            :class="{ isDeactive: isLoading }"
                            class="btn strong w_xs h_m"
                          >
                            {{
                              item.salesMethod === 1
                                ? 'For Sale'
                                : 'Not for Sale'
                            }}
                          </button>
                          <button
                            v-else-if="item.bcNwTp == '20'"
                            :class="{ isDeactive: isLoading }"
                            class="btn strong w_xs h_m"
                            @click="purchaseEvt(item)"
                            :disabled="
                              !item.salesMethod || item.salesMethod === 1
                                ? true
                                : false
                            "
                          >
                            {{
                              !item.salesMethod || item.salesMethod === 1
                                ? 'Not for Sale'
                                : ethWallet.walletYn === 'N' ||
                                  !getWeb3ModalStatus.address
                                ? 'Connect'
                                : 'Buy Now'
                            }}
                          </button>
                          <button
                            v-else
                            :class="{ isDeactive: isLoading }"
                            class="btn strong w_xs h_m"
                            @click="purchaseEvt(item)"
                            :disabled="
                              !item.salesMethod || item.salesMethod === 1
                                ? true
                                : false
                            "
                          >
                            {{
                              !item.salesMethod || item.salesMethod === 1
                                ? 'Not for Sale'
                                : 'Purchase'
                            }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <InfinityScroll @scroll="getStatusOrEditions(true)" />
            </div>
          </template>
          <template v-if="currentTab === 'docent'">
            <DocentThumbSlide
              :item-data="docentThumbItems"
              @setCurrent="setDocentCurrent"
            />
            <div class="viewer_wrap type_img">
              <div class="img_area">
                <img
                  :src="docentThumbImage"
                  :width="1220"
                  alt="docentThumbnail"
                />
                <!-- :alt="
                    currentDocent.item.image.alt
                      ? currentDocent.item.image.alt
                      : ''
                  " -->
              </div>
              <div class="desc_area">
                <p>{{ currentDocent.item.desc }}</p>
              </div>
            </div>
          </template>
          <template v-if="currentTab === 'film'">
            <div class="viewer_wrap type_video">
              <div class="video_area" style="text-align: center">
                <video
                  ref="video"
                  @ended="isPlay = false"
                  :src="makingInfos[0].resUrl"
                  id="makingFilm"
                  :aria-label="'makingFilm for ' + editionInfos.title"
                >
                  <!-- <source :src="this.makingInfos[0].resUrl" type="video/mp4" /> -->
                </video>
              </div>
              <div class="controls_area type_start">
                <button class="btn type_ic player" @click="makingfilmPlayPause">
                  <IcPlayL
                    v-if="!isPlay"
                    class="ic_play"
                    :isPlayer.sync="isPlayer"
                  />
                  <IcPauseL v-else class="ic_pause" />
                </button>
              </div>
            </div>
          </template>
        </div>
      </section>
    </div>
    <QrModal
      :modal-data="currentQrInfo"
      :showQrModal="showQrModal"
      :editionId="editionId"
      :artworkId="artworkId"
      @close="close"
      :modal-id="modalId"
      page="Marketplace"
    />
    <SetPriceModal
      :showPriceModal="showPriceModal"
      :editionId="itemEditionId"
      :artworkId="artworkId"
      modal-size="m"
      @close="close"
    />
    <SetEthModal
      :showEthModal="showEthModal"
      :editionId="itemEditionId"
      :artworkId="artworkId"
      modal-size="m"
      @close="close"
    />
    <EthLoading
      :showEthLoading="showEthLoading"
      modal-size="m"
      @close="close"
    />
    <ConnectWalletModal :showWallet="showWallet" @close="close" />
    <InsufficientBalance
      :showUsdc="showUsdc"
      :associatedSign="associatedSign"
      @close="close"
      :modal-data="usdcModalInfo"
    />
    <UpdateInfoModal
      :price="showInfoEditionPrice"
      :priceToEthUsd="showInfoEditionPriceToEthUsd"
      :selectId="selectEditionId"
      :bcNwTp="selectbcNwtp"
      ref="UpdateInfoModal"
      @zipcodeSave="zipcodeSave"
    />
    <PurchaseProgressModal :showEthProgress="showEthProgress" @close="close" />
  </main>
</template>

<script>
import IcLike from '@/components/ic/IcLike';
import IcTime from '@/components/ic/IcTime';
import IcItems from '@/components/ic/IcItems';
import IcEye from '@/components/ic/IcEyes';
import IcVideo from '@/components/ic/IcVideo';
import IcProfile from '@/components/ic/IcProfile';
// import IcRefreshS from '@/components/ic/IcRefreshS';
import IcCopy from '@/components/ic/IcCopy';
import IcPlayL from '@/components/ic/IcPlayL';
import IcPauseL from '@/components/ic/IcPauseL';
import QrModal from '@/components/common/QrModal';
import SetPriceModal from '@/components/common/SetPriceModal';
import SetEthModal from '@/components/common/SetEthModal';
import EthLoading from '@/components/common/ethLoading';
import IcRing from '@/components/ic/IcRing';
import IcEthS from '@/components/ic/IcEthS';
import IcHederaS from '@/components/ic/IcHederaS';
import DocentThumbSlide from '@/components/drops/DocentThumbSlide';
import ConnectWalletModal from '@/components/marketplace/ConnectWalletModal';
import InsufficientBalance from '@/components/marketplace/InsufficientBalance';
import UpdateInfoModal from '@/components/marketplace/UpdateInfoModal';
import PurchaseProgressModal from '@/components/marketplace/PurchaseProgressModal';
import ArwSelect from '@/components/ic/arwSelect';
import { mapGetters, mapMutations } from 'vuex';
import InfinityScroll from '@/components/common/InfinityScroll';
import LoadingSpinner from '@/components/common/LoadingSpinner';

export default {
  name: 'MarketplaceDetailView',
  components: {
    ArwSelect,
    UpdateInfoModal,
    ConnectWalletModal,
    InsufficientBalance,
    DocentThumbSlide,
    IcRing,
    SetPriceModal,
    QrModal,
    IcPauseL,
    IcPlayL,
    IcCopy,
    // IcRefreshS,
    IcProfile,
    IcVideo,
    IcEye,
    IcItems,
    IcTime,
    IcLike,
    IcEthS,
    IcHederaS,
    InfinityScroll,
    PurchaseProgressModal,
    SetEthModal,
    EthLoading,
    LoadingSpinner,
  },
  data() {
    return {
      likeStatus: false,
      source: '',
      source_medium: '',
      source_small: '',
      totalEdition: '',
      favorites: false,
      polling: null,
      polling_refresh: null,
      contractAddress: '',
      usdcBalance: 0,
      editionItems: [],
      statusInfos: [],
      artistAvatarImg: '',
      artistName: '',
      artworkId: '',
      editionId: 0,
      itemEditionId: 0,
      artworkBackground: '',
      artworkAlt: '',
      showQrModal: false,
      showWallet: false,
      showUsdc: false,
      showPriceModal: false,
      docentThumbItems: [],
      editionInfos: {},
      associatedYn: 'N',
      isLoading: false,
      currentQrInfo: {},
      tabQueue: [
        {
          id: 'status',
          name: 'Current status',
          icon: 'time',
          class: 'type_status',
        },
        {
          id: 'editions',
          name: 'Editions',
          icon: 'items',
          class: 'type_edit',
        },
        {
          id: 'docent',
          name: 'Docent Art Show',
          icon: 'eye',
          class: 'type_docent',
        },
        {
          id: 'film',
          name: 'Making Film',
          icon: 'video',
          class: 'type_film',
        },
      ],
      infoTabData: [],
      currentTab: '',
      keywordData: [
        'Illustration',
        'Animation',
        'Color',
        '3D',
        'Poetry',
        'Blender',
        'Abstract',
        'Surreal',
        'Photography',
        'Surrealism',
      ],
      startVideo: false,
      currentDocent: {
        idx: 0,
        item: '',
      },
      isPlay: false,
      loginAlert: {
        type: 'warn_2',
        desc: 'Please sign in.',
        success: 'Sign in',
      },
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
      purchaseData: {
        title: 'Purchase',
        desc: 'To purchase artworks, turn on the camera on your mobile phone and scan the QR.',
        qr: {
          path: 'img_qr.png',
        },
      },
      changeSelling: {
        title: 'Change Selling Type',
        desc: 'Turn on the camera on your mobile phone and scan the QR to change the selling type.',
        qr: {
          path: 'img_qr.png',
        },
      },
      statusOpt: [
        {
          label: 'Not for Sale',
          value: '10',
        },
        {
          label: 'Price',
          value: '30',
        },
      ],
      showSelect: false,
      showEthModal: false,
      showEthLoading: false,
      walletSelect: false,
      walletOpt: {},
      walletOptData: [],
      makingInfos: {},
      walletFocusOpt: 0,
      tabPos: 0,

      isMob: false,
      hasWallet: false,
      MYADDR: false,
      showMoreDesc: false,
      overtimer: 180000,
      signAddr: '',
      showPriceTax: false,
      isPlayer: true,
      playerTimeoutId: null,
      accountId: '',
      accountInfo: {
        title: 'Create USDC Coin Account',
        desc: "We have noticed you don't have a USDC Coin account. Please sign in to create USDC Coin account in Wallypto to purchase.",
        button: 'Go to Wallypto',
      },
      // NOTE: 220826 modal message change requested by pm
      InsufficientModalInfo: {
        title: 'Add USDC Coin',
        desc: "We have noticed you don't have enough USDC Coin in your Wallypto.\n Please buy USDC Coin from exchanges and add the USDC Coin token to your Wallypto app to check final balance.",
        button: 'Purchase USDC Coin',
      },
      associatedSign: '',
      usdcModalInfo: undefined,
      associatedError: false,
      walletTokenExpired: false,
      zipCodeCheck: false,
      selectEditionId: '',
      selectbcNwtp: '',
      showInfoEditionPrice: 0,
      showInfoEditionPriceToEthUsd: 0,
      showEthProgress: false,
      resultPrice: 0,
      pageObj: {
        page: 1,
        cnt: 20,
      },
      isStatusLoading: false,
    };
  },
  created() {
    this.$root.$emit('setPage', {
      page: 'detail_page',
    });
  },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
      stateYn: 'userStore/stateYn',
      mbrNo: 'userStore/mbrNo',
      userType: 'userStore/userType',
      wlltType: 'userStore/wlltType',
      email: 'userStore/email',
      ethWallet: 'userStore/ethWallet',
      hdrWallet: 'userStore/hdrWallet',
      getWeb3ModalStatus: 'web3Store/getWeb3ModalStatus',
    }),
    taxOnly() {
      return this.editionInfos.tax;
    },
    isMine() {
      if (this.editionInfos.bcNwTp == '20') {
        return (
          this.accessToken &&
          this.editionInfos.ownrWlltAcnt.toLowerCase() ==
            this.getWeb3ModalStatus?.address?.toLowerCase()
        );
      } else {
        return (
          this.editionInfos.ownerId &&
          this.mbrNo &&
          this.editionInfos.ownerId == this.mbrNo
        );
      }
    },
    modalId() {
      return this.editionInfos.ownerId == this.mbrNo
        ? 'changeSellingModal'
        : 'purchaseModal';
    },
    isMultiEdition() {
      return this.editionInfos.editionType == 20;
    },
    openBlade() {
      return JSON.parse(process.env.VUE_APP_OPEN_BLADE) ? true : false;
    },
    openWallypto() {
      return JSON.parse(process.env.VUE_APP_OPEN_WALLYPTO) ? true : false;
    },
  },
  watch: {
    accessToken(newVal, oldVal) {
      if (newVal == oldVal) {
        return null;
      }
      this.setSelectWallet();
      this.zipCodeValidation();
      this.getMarketplaceArtworkOrEdition();
    },
  },
  async mounted() {
    //this.tab.id = this.editionId > 0 ? 'status' : 'editions';
    this.isMob = this.$isMobile();
    window.addEventListener('scroll', this.setFixedTab);
    await this.setSelectWallet();
    await this.zipCodeValidation();
    await this.getMarketplaceArtworkOrEdition();
  },
  methods: {
    async setSelectWallet() {
      let blade = {
        label: 'Blade Wallet',
        value: 'WLT001',
      };

      let wallypto = {
        label: 'Wallypto',
        value: 'WLT002',
      };

      if (this.openBlade) {
        this.walletOptData.push(blade);
      }
      if (this.openWallypto) {
        this.walletOptData.push(wallypto);
      }

      if (this.hdrWallet.walletYn === 'Y') {
        this.walletOpt = this.wlltType === 'WLT002' ? wallypto : blade;
      } else {
        this.walletOpt = this.openBlade ? blade : wallypto;
      }
    },
    async zipCodeValidation() {
      if (!(await this.checkZipcode()) && this.stateYn === 'N') {
        this.zipCodeCheck = false;
      } else {
        this.zipCodeCheck = true;
      }
    },
    goMultiEdition() {
      if (this.isMultiEdition && this.editionId > 0 && this.totalEdition > 1) {
        this.clearTimer();
        this.$router.push({
          name: 'marketplaceDetail',
          query: {
            artworkId: this.artworkId,
            editionId: 0,
          },
        });
        this.getMarketplaceArtworkOrEdition();
      }
    },
    goArtworkDetail(artworkId, editionId) {
      this.clearTimer();
      this.$router.push({
        name: 'marketplaceDetail',
        query: {
          artworkId,
          editionId,
        },
      });
    },
    close(type) {
      this[type] = false;
      this.isLoading = false;
      if (this.editionInfos.bcNwTp == '20') {
        this.showEthLoading = false;
        this.showEthModal = false;
      } else {
        this.showPriceModal = false;
        this.showQrModal = false;
        this.showWallet = false;
        this.showInfoEditionPrice = false;
      }
      this.getMarketplaceArtworkOrEdition();
    },
    goTagSearch(tag) {
      this.clearTimer();
      this.$router.push({
        name: 'search',
        params: { keyword: tag },
      });
    },
    async openAppPurchase(editionId) {
      if (this.walletOpt.value === 'WLT001' && !this.openBlade) {
        this.$root.$emit('openAlert', this.appMessage('WLT001'));
        return false;
      }
      if (this.walletOpt.value === 'WLT002' && !this.openWallypto) {
        this.$root.$emit('openAlert', this.appMessage('WLT002'));
        return false;
      }
      let walletParams = {
        artworkId: this.artworkId,
        editionId: editionId,
      };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      //}

      this.isLoading = true;
      await this.api.getQrUrlForMarketPurchase(walletParams).then(res => {
        this.isLoading = false;
        let walletInfo = {
          walletType: this.walletOpt.value,
          resultPrice: this.resultPrice,
        };
        if (res.code === 0) {
          if (this.walletOpt.value === 'WLT001') {
            if (!this.resultPrice) return false;
            let params = {
              hostServer: res.hostServer,
              signAddr: res.signAddr,
              scheme: 'purchase',
              price: this.resultPrice,
              purchaseType: 'marketplace',
            };
            this.onBlade(params, this.email);
          } else if (this.walletOpt.value === 'WLT002') {
            this.onCallApp(res.signUrl);
          } else {
            this.$router.push({
              name: 'network',
            });
          }

          this.clearTimer();
          this.$router.push({
            name: 'purchase',
            params: {
              signAddr: res.signAddr,
              purchase: 'market',
            },
          });
        } else if (res.code === 1033) {
          this.$root.$emit('walletTokenExpired', walletInfo);
        } else if (res.code === 1034) {
          this.$root.$emit('walletChange', walletInfo);
        } else {
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    loginPopup() {
      this.$EventBus.$emit('empLogin');
    },
    copy() {
      let addr = this.contractAddress;
      const textarea = document.createElement('textarea');

      textarea.textContent = addr;
      document.body.append(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      this.$toast.open('Saved to clipboard');
    },
    makingfilmPlayPause() {
      if (this.isPlay === false) {
        this.isPlay = true;
        this.$refs.video.play();

        this.playerTimeoutId = setTimeout(() => {
          this.isPlayer = false;
        }, 3000);
      } else {
        this.isPlay = false;
        this.$refs.video.pause();
        clearTimeout(this.playerTimeoutId);
      }
    },
    mousePlayOver() {
      this.isPlayer = true;
    },
    mousePlayleave() {
      this.isPlayer = false;
      clearTimeout(this.playerTimeoutId);
    },
    tabManager(editionId) {
      // tab No.
      //  0 => 멀티,  edition,  condition => editionId = 0
      // 1 => 싱글, status, condition => editionId > 0
      // 2 => docent
      // 3 => makingFilm

      this.infoTabData = [];

      if (editionId > 0) {
        //0이 아닌 경우는 말단의 싱글에디션
        let tab = this.tabQueue.find(tab => tab.id == 'status');
        this.infoTabData.push(tab);
      } else {
        //0이면 멀티에디션이므로 status가 아닌 editions로 노출
        let tab = this.tabQueue.find(tab => tab.id == 'editions');
        this.infoTabData.push(tab);
      }

      if (this.docentInfo?.items.length) {
        let tab = this.tabQueue.find(tab => tab.id == 'docent');
        this.infoTabData.push(tab);
      }
      if (this.makingInfos.length) {
        let tab = this.tabQueue.find(tab => tab.id == 'film');
        this.infoTabData.push(tab);
      }
    },
    likeThis(item) {
      if (!this.$refs.icLike.loginCheck()) {
        return;
      } else {
        let artworkId = item.artworkId;
        let editionId = item.editionId;
        let favoritesYn = this.likeStatus === true ? 'N' : 'Y';

        // let editionType = this.editionInfos.editionType;

        editionId = !editionId ? 0 : editionId;

        let params = {
          artworkId,
          editionId,
          favoritesYn,
        };
        //   let editionFavoritesResult = null;
        //   // editionType 10 : 싱글에디션
        //   // editionType 20 : 멀티에디션

        if (editionId == 0) {
          // 멀티에디션일경우
          this.$refs.icLike.likeArtwork(params);
        } else {
          // 싱글에디션일 경우
          this.$refs.icLike.likeEdition(params);
        }
        this.likeStatus = !this.likeStatus;
      }
    },

    // 멀티에디션의 하위에디션 목록에서 썸네일에 좋아요 하는 경우

    async likeThisEdition(artworkId, editionId, idx) {
      if (!this.$refs.icLike.loginCheck()) {
        return;
      } else {
        let favoriteCheck = this.editionItems[idx].favorites == 'Y' ? 'N' : 'Y';

        let params = {
          artworkId,
          editionId,
          favoritesYn: favoriteCheck,
        };

        await this.api.setEditionFavorites(params).then(res => {
          if (res.code === 0) {
            this.editionItems[idx].favorites = favoriteCheck;
          }
        });
      }
    },
    setTab(id) {
      this.currentTab = id;
      this.$refs.tabs.scrollIntoView();
    },
    openDrop() {
      this.showSelect = !this.showSelect;
    },
    walletOtp(opt, idx) {
      this.walletSelect = false;
      if (opt.value === this.walletOpt.value) {
        return;
      }
      this.walletOpt = opt;
      this.walletFocusOpt = idx;
    },

    async orderStatusChange(item, opt) {
      this.showSelect = false;
      if (opt.value === '10') {
        if (item.salesMethod === 1) {
          return false;
        }

        if (this.editionInfos.bcNwTp == '20') {
          if (!this.getWeb3ModalStatus.address) {
            this.W3M.open();
            return false;
          }
          //ETH
          let walletParams = {
            artworkId: this.artworkId,
            editionId: item.editionId,
            //price: item.lowestPrice,
            price: '0',
            priceSettingTp: opt.value,
            bcNwTp: '20',
          };
          if (this.isLoading) return false;

          this.showEthLoading = true;
          this.isLoading = true;

          try {
            let cancelResult = await this.api.getQrUrlForSalePriceSetting(
              walletParams,
            );
            if (cancelResult.code === 0) {
              let txResult = await this.W3F.sendTransaction({
                from: this.getWeb3ModalStatus.address,
                data: cancelResult.cancleResponse.data,
                to: cancelResult.cancleResponse.to,
              });

              let hash = txResult;

              // this.getWeb3ModalStatus.providerType == 'walletConnect'
              //   ? txResult
              //   : txResult.hash;

              if (txResult) {
                let ethCancelParam = {
                  transactionId: cancelResult.cancleResponse.transactionId,
                  transactionHash: hash,
                  signAddr: cancelResult.signAddr,
                };
                let cancelRegister = await this.api.getMarketEthCancelRegister(
                  ethCancelParam,
                );
                if (cancelRegister.code === 0) {
                  this.polling = setInterval(() => {
                    this.ethPermissionCallback(cancelResult);
                    if (this.overtimer <= 0) {
                      this.showEthLoading = false;
                      this.isLoading = false;
                      clearInterval(this.polling);
                    }
                    this.overtimer -= 3000;
                  }, 3000);
                } else {
                  this.showEthLoading = false;
                  this.isLoading = false;
                  let errorMsg = {
                    type: 'warn',
                    desc: cancelRegister.message,
                  };
                  this.$root.$emit('openAlert', errorMsg);
                  this.getMarketplaceArtworkOrEdition();
                }
              }
            } else {
              this.showEthLoading = false;
              this.isLoading = false;
              let errorMsg = {
                type: 'warn',
                desc: cancelResult.message,
              };
              this.$root.$emit('openAlert', errorMsg);
              this.getMarketplaceArtworkOrEdition();
            }
          } catch (e) {
            let errorMsg = {
              type: 'warn',
              desc: e.info?.error?.message ?? e.message ?? 'MetaMask Error',
            };
            this.$root.$emit('openAlert', errorMsg);
            this.showEthLoading = false;
            this.isLoading = false;
            this.getMarketplaceArtworkOrEdition();
          }
        } else {
          //Hedera
          let walletParams = {
            artworkId: this.artworkId,
            editionId: item.editionId,
            price: item.lowestPrice,
            //price: '0',
            priceSettingTp: opt.value,
            bcNwTp: '10',
          };

          let walletInfo = {
            walletType: this.walletOpt.value,
            resultPrice: item.lowestPrice,
          };

          let contractAddress = '';
          if (this.editionId == 0) {
            let artworkDetailResult = await this.api.getEditionDetail({
              artworkId: this.artworkId,
              editionId: item.editionId,
            });
            contractAddress = artworkDetailResult.editionInfos.contractAddress;
          } else {
            contractAddress = this.editionInfos.contractAddress;
          }
          let serial = contractAddress.split('@')[0];

          if (this.isLoading) return false;
          this.isLoading = true;

          this.api.getQrUrlForSalePriceSetting(walletParams).then(res => {
            this.isLoading = false;
            if (res.code === 0) {
              if (this.walletOpt.value === 'WLT001') {
                let params = {
                  hostServer: res.hostServer,
                  signAddr: res.signAddr,
                  scheme: 'setup',
                  price: -1,
                  type: 'notforsale',
                  serial: serial,
                };
                this.onBlade(params, this.email);
              } else if (this.walletOpt.value === 'WLT002') {
                this.onCallApp(res.signUrl);
              } else {
                this.$router.push({
                  name: 'network',
                });
              }
              this.signAddr = res.signAddr;
              this.polling = setInterval(() => {
                this.getAppCallback();
                if (this.overtimer <= 0) {
                  this.isLoading = false;
                  clearInterval(this.polling);
                }
                this.overtimer -= 3000;
              }, 3000);
            } else if (res.code === 1033) {
              this.$root.$emit('walletTokenExpired', walletInfo);
            } else if (res.code === 1034) {
              this.$root.$emit('walletChange', walletInfo);
            } else {
              this.$root.$emit('openAlert', this.apiFailAlert);
            }
          });
        }
      } else {
        if (opt.value === '30') {
          this.itemEditionId = item.editionId;
          if (this.editionInfos.bcNwTp == '20') {
            this.showEthModal = true;
          } else {
            this.showPriceModal = true;
          }
        }
      }
    },
    async ethPermissionCallback(cancelResult) {
      await this.api
        .getDropPurchaseCmpltYn({ signAddr: cancelResult.signAddr })
        .then(async res => {
          if (res.code == 0) {
            try {
              if (res.purchaseCmpltYn === 'Y' || res.purchaseCmpltYn === 'N') {
                let ethCallbackMsg;
                this.showEthLoading = false;
                this.isLoading = false;
                if (res.purchaseCmpltYn === 'Y') {
                  clearInterval(this.polling);
                  ethCallbackMsg = {
                    type: 'success',
                    desc: 'The change was successful. Updating information,\nplease wait.',
                  };
                  await this.getMarketplaceArtworkOrEdition();
                } else {
                  clearInterval(this.polling);
                  ethCallbackMsg = {
                    type: 'warn',
                    desc: 'The change failed.',
                  };
                  this.$root.$emit('openAlert', ethCallbackMsg);
                }
              }
            } catch (e) {
              let errorMsg = {
                type: 'warn',
                desc: e.info?.error?.message ?? e.message ?? 'MetaMask Error',
              };
              this.$root.$emit('openAlert', errorMsg);
              this.showEthLoading = false;
              this.isLoading = false;
              clearInterval(this.polling);
            }
          } else {
            let errorMsg = {
              type: 'warn',
              desc: res.message,
            };
            this.$root.$emit('openAlert', errorMsg);
            this.showEthLoading = false;
            this.isLoading = false;
            clearInterval(this.polling);
          }
        });
    },
    async getAppCallback() {
      let params = {
        signAddr: this.signAddr,
      };
      await this.api.getPriceSettingCmpltYn(params).then(res => {
        if (res.code === 0) {
          if (res.cmpltYn === 'Y' || res.cmpltYn === 'N') {
            clearInterval(this.polling);
            this.getMarketplaceArtworkOrEdition();
          }
        } else {
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    openModal(ref) {
      this.$refs[ref].open();
    },
    async checkZipcode() {
      if (!this.accessToken) return false;
      return await this.api.getZipCode({}).then(res => {
        if (res.code === 0) {
          return true;
        } else {
          return false;
        }
      });
    },
    setFixedTab() {
      const st = window.pageYOffset;
      const tabWrap = this.$refs.tabs;
      const topGap = window.innerWidth <= 1024 ? 60 : 0;
      this.tabPos = tabWrap.getBoundingClientRect().top + st;
      if (this.tabPos - topGap < st) tabWrap.classList.add('is_fix');
      else tabWrap.classList.remove('is_fix');
    },
    setDocentCurrent(item, idx) {
      this.currentDocent = { idx, item };
    },

    async ethPurchase(editionId) {
      let purchaseParams = {
        bcNwTp: '20',
        artworkId: this.artworkId,
        editionId: editionId,
      };

      if (!this.getWeb3ModalStatus.address) {
        this.W3M.open();
        return false;
      }

      if (this.isLoading) return false;

      this.isLoading = true;
      this.showEthProgress = true;
      await this.api.getMarketEthPurchase(purchaseParams).then(async res => {
        if (res.code === 0) {
          try {
            let mint = res.transaction;
            let transactionId = res.transactionId;
            let txResult = await this.W3F.sendTransaction({
              from: this.getWeb3ModalStatus.address,
              data: mint.data,
              to: mint.to,
              value: this.toHexEth(mint.value),
            });

            let hash = txResult;

            // this.getWeb3ModalStatus.providerType == 'walletConnect'
            //   ? txResult
            //   : txResult.hash;

            if (txResult) {
              let ethParams = {
                transactionId: transactionId,
                transactionHash: hash,
              };
              this.ethRegister(ethParams, transactionId);
            } else {
              this.ethClear();
            }
          } catch (e) {
            let errMsg;
            let errorMsg;
            let haveVL = '';
            let wantVL = '';
            let isNotEnough = false;
            if (e.info.error.code == -32000) {
              errMsg = `You don't have enough ETH
              in your wallet for this transaction.`;
              try {
                const haveValue = e.info?.error?.message?.split('have ');
                const wantValue = e.info?.error?.message?.split('want ');
                wantVL = wantValue[1] / 1000000000000000000;
                haveVL = haveValue[1].split(' ')[0] / 1000000000000000000;
                isNotEnough = true;

                errorMsg = {
                  type: 'warn',
                  desc: errMsg,
                  titleDescription: isNotEnough ? 'Not enough ETH' : null,
                  additional: [
                    {
                      label: 'Remaining',
                      value: parseFloat(haveVL).toFixed(3) + ' ETH',
                    },
                    {
                      label: 'Required',
                      value: parseFloat(wantVL).toFixed(3) + ' ETH',
                    },
                  ],
                };
              } catch (er) {
                console.log(er);
              }
            } else {
              errMsg = e.info?.error?.message ?? e.message ?? 'MetaMask Error'; //eslint-disable-line no-unused-vars
              errorMsg = {
                type: 'warn',
                desc: errMsg,
              };
            }

            this.$root.$emit('openAlert', errorMsg);
            this.ethClear();
          }
          //code 1071 listing fail
        } else {
          let errMsg;
          let errorMsg;
          let haveVL = '';
          let wantVL = '';
          let isNotEnough = false;
          if (res.code == -32000) {
            errMsg = `You don't have enough ETH
            in your wallet for this transaction.`;
            try {
              const haveValue = res.message?.split('have ');
              const wantValue = res.message?.split('want ');
              wantVL = wantValue[1];
              haveVL = haveValue[1].split(' ')[0];
              isNotEnough = true;

              errorMsg = {
                type: 'warn',
                desc: errMsg,
                titleDescription: isNotEnough ? 'Not enough ETH' : null,
                additional: [
                  {
                    label: 'Remaining',
                    value: parseFloat(haveVL).toFixed(3) + ' ETH',
                  },
                  {
                    label: 'Required',
                    value: parseFloat(wantVL).toFixed(3) + ' ETH',
                  },
                ],
              };
            } catch (er) {
              console.log(er);
            }
          } else {
            errMsg = res.message ?? 'MetaMask Error'; //eslint-disable-line no-unused-vars
            errorMsg = {
              type: 'warn',
              desc: errMsg,
            };
          }
          this.$root.$emit('openAlert', errorMsg);
          this.ethClear();
        }
      });
    },
    async ethRegister(params, transactionId) {
      await this.api.getMarketEthRegster(params).then(res => {
        if (res.code === 0) {
          this.polling = setInterval(() => {
            this.ethCallback(transactionId);
            if (this.overtimer <= 0) {
              this.ethClear();
            }
            this.overtimer -= 3000;
          }, 3000);
        } else {
          let errorMsg = {
            type: 'warn',
            desc: res.message,
          };
          this.$root.$emit('openAlert', errorMsg);
          this.ethClear();
        }
      });
    },
    async ethCallback(transactionId) {
      let callBackParams = {
        transactionId,
      };
      await this.api.getMarketEthCallback(callBackParams).then(res => {
        if (res.code === 0) {
          let ethCallbackMsg;
          if (res.purchaseCmpltYn === 'Y') {
            this.ethClear();

            let contractAddr =
              res.contractAddr.length > 15
                ? res.contractAddr.substr(0, 8) +
                  '...' +
                  res.contractAddr.substr(
                    res.contractAddr.length - 3,
                    res.contractAddr.length,
                  )
                : res.contractAddr;
            ethCallbackMsg = {
              type: 'success',
              desc: `Your purchase has been completed.\nContract Addr: ${contractAddr}\n tokenId: ${res.tokenId}\nThank you.`,
            };
            this.$root.$emit('openAlert', ethCallbackMsg);
          } else if (res.purchaseCmpltYn === 'N') {
            ethCallbackMsg = {
              type: 'warn',
              desc: 'purchaseCmpltYn result : N',
            };
            this.$root.$emit('openAlert', ethCallbackMsg);
            this.ethClear();
          }
        } else {
          let errorMsg = {
            type: 'warn',
            desc: res.message,
          };
          this.$root.$emit('openAlert', errorMsg);
          this.ethClear();
        }
      });
    },
    ethClear() {
      this.getMarketplaceArtworkOrEdition();
      this.showEthProgress = false;
      this.isLoading = false;
      if (this.polling) clearInterval(this.polling);
    },
    //type : changeType Case
    async purchaseEvt(item, type) {
      if (this.editionInfos.bcNwTp == '10' && !this.isMob) {
        this.purchaseQRdata(item);
        return false;
      }
      if (!this.accessToken) {
        this.loginPopup();
        return false;
      }
      if (type === 'changeType') {
        if (
          this.editionInfos.bcNwTp == '10' &&
          this.hdrWallet.walletYn === 'N'
        ) {
          this.connectWallet();
          return false;
        }
        //Multi Edition Case 추가기능 예외처리
        item.salesMethod === 1
          ? this.orderStatusChange(item, this.statusOpt[1]) // for sale
          : this.orderStatusChange(item, this.statusOpt[0]); //not for sale

        return false;
      }

      if (!(await this.checkZipcode()) && this.stateYn === 'N') {
        this.openModal('UpdateInfoModal');
        this.selectEditionId = item.editionId;
        this.selectbcNwtp = item.bcNwTp;
        this.showInfoEditionPrice = parseFloat(item.price);
        this.showInfoEditionPriceToEthUsd = item.priceToEthUsd;
        return false;
      }

      if (this.isLoading) return false;

      if (this.editionInfos.bcNwTp == '20') {
        this.ethPurchase(item.editionId);
      } else {
        let resultTax = item.tax ? item.tax : 0;
        this.resultPrice = (
          parseFloat(item.price) + parseFloat(resultTax)
        ).toFixed(2);

        if (this.walletOpt.value === 'WLT001') {
          // Blade
          if (this.hdrWallet.walletYn === 'N') {
            this.connectWallet();
          } else {
            this.openAppPurchase(item.editionId);
          }
        } else {
          let insufficientBalance = false;
          let walletInfo = {
            walletType: this.walletOpt.value,
            resultPrice: 0,
          };

          if (this.hdrWallet.walletYn === 'N') {
            this.showWallet = true;
            return false;
          } else {
            await this.getWalletCheck();

            let resultUsdc = parseFloat(this.usdcBalance).toFixed(2);
            if (Number(this.resultPrice) >= Number(resultUsdc)) {
              insufficientBalance = true;
            }
          }
          if (this.walletTokenExpired)
            this.$root.$emit('walletTokenExpired', walletInfo);
          else if (this.walletChange)
            this.$root.$emit('walletChange', walletInfo);
          else if (this.associatedYn === 'N') {
            if (this.associatedError) {
              this.$root.$emit('openAlert', this.apiFailAlert);
            } else {
              this.showUsdc = true;
              this.usdcModalInfo = this.accountInfo;
            }
          } else if (insufficientBalance) {
            this.showUsdc = true;
            this.associatedSign = '';
            // NOTE: 220826 modal message change requested by pm
            this.usdcModalInfo = this.InsufficientModalInfo;
          } else this.openAppPurchase(item.editionId);
        }
      }
    },
    async connectWallet() {
      if (this.isLoading) return false;
      this.isLoading = true;
      clearInterval(this.polling);

      let walletParams = {
        wlltType: 'WLT001',
      };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      //}

      await this.api.connectWallet(walletParams).then(res => {
        if (res.code === 0) {
          let params = {
            hostServer: res.hostServer,
            signAddr: res.signAddr,
            scheme: 'setup',
            price: this.resultPrice,
            purchaseType: 'marketplace',
          };
          this.onBlade(params, this.email);

          setTimeout(() => {
            this.isLoading = false;
          }, 10000);

          this.polling = setInterval(() => {
            this.getProfile();
            if (this.overtimer <= 0) {
              this.isLoading = false;
              clearInterval(this.polling);
            }
            this.overtimer -= 3000;
          }, 3000);
        } else {
          this.isLoading = false;
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    async getProfile() {
      await this.api.getProfile({}).then(res => {
        if (res.code === 0) {
          let hdrWallet = res.walletInfos
            ? res.walletInfos.find(v => v.bcNwTp === '10')
            : {};
          if (hdrWallet.walletYn === 'Y') {
            this.isLoading = false;
            this.setHdrWallet(hdrWallet);
            this.setWlltType(res.wlltType);
            clearInterval(this.polling);
            this.getNftBalance();
            this.getMarketplaceArtworkOrEdition();
          }
        } else {
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    // getRefresh() {
    //   this.polling_refresh = setInterval(() => {
    //     this.getStatusOrEditions(false, true);
    //   }, 120000);
    // },

    /*
      if (this.pageObj.cnt < this.totalCount) {
        this.pageObj.page++;
        this.pageObj.cnt += 20;
        let params = {
          page: this.pageObj.page,
          artworkId: this.artworkId,
        };
        this.api.getArtworkEditionList(params).then(res => {
          if (res.code === 0) {
            this.editionItems.push(...res.editionItems);
          }
        });
      }\

    */
    async getStatusOrEditions(scroll = false, refresh = false) {
      if (this.editionId === 0) {
        let params = { artworkId: this.artworkId };

        if (scroll) {
          if (this.pageObj.cnt < this.totalCount && !this.isStatusLoading) {
            this.pageObj.page++;
            this.pageObj.cnt += 20;
          } else {
            return false;
          }
        }
        if (this.pageObj.page == 1) {
          this.isStatusLoading = true;
        }
        if (this.pageObj.page > 1 && this.isStatusLoading) {
          return false;
        }

        params.page = this.pageObj.page;

        if (refresh) params.cnt = this.pageObj.cnt;

        await this.api.getArtworkEditionList(params).then(res => {
          this.isStatusLoading = false;
          if (res.code === 0) {
            // 멀티에디션일경우 하위 에디션들 정보 받아옴
            this.editionItems =
              scroll == true
                ? [...this.editionItems, ...res.editionItems]
                : res.editionItems;

            //0209 refactoring this.isAllEditionsNotForSale() ; 제거 map- > find
            // isAllEditionsNotForSale :
            // 하위 에디션들이 모두 NFS 경우일 때는 tax/price 정보를 숨겨야해서 기능을 넣음.
            let findResult = this.editionItems.find(item => {
              return item.salesMethod !== 1;
            });

            if (findResult) this.showPriceTax = true;
          }
        });
      } else {
        this.isStatusLoading = true;
        await this.api
          .getArtworkEditionStatus({
            artworkId: this.artworkId,
            editionId: this.editionId,
          })
          .then(res => {
            this.isStatusLoading = false;
            if (res.code === 0) {
              this.statusInfos = res.statusInfos;
              this.statusInfos.forEach(x => {
                if (x.contents.indexOf(x.activityName) == -1) {
                  x.action = x.contents;
                  x.date = '';
                  x.actionType = 'created';
                } else {
                  x.action = x.contents.substr(
                    0,
                    x.contents.indexOf(x.activityName),
                  );
                  x.actionType = 'normal';
                }
              });
              // 0628 insung.jo 아래 status정보를 뽑아서
              // 추가된 정책으로 lastSale, ownerid정보만 갈아 엎었음
              // 2차추가 뽑아온 정보의 지갑과 내연결된 지갑비교를 추가해야됨..
              for (let item of res.statusInfos) {
                if (item.activityType === '20' || item.activityType === '60') {
                  this.editionInfos.currentPrice = item.price;
                  this.editionInfos.currentPriceToEthUsd = item.priceToEthUsd;
                  this.editionInfos.ownerName = item.activityName;
                  this.editionInfos.ownerAvatarInfo = item.activityAvatarInfo;
                  this.editionInfos.lastSaleDate = item.date;
                  this.editionInfos.ownrWlltAcnt = item.ownrWlltAcnt;
                  return;
                }
              }
            }
          });
      }
    },

    async getMarketplaceArtworkOrEdition() {
      this.artworkId = this.$route.query.artworkId;
      this.editionId = parseInt(this.$route.query.editionId);
      this.itemEditionId = this.editionId;
      let artworkDetailResult;
      let query = {
        artworkId: this.artworkId,
      };

      if (this.editionId != 0) {
        //싱글에디션일경우
        query = {
          ...query,
          editionId: this.editionId,
        };
        artworkDetailResult = await this.api.getEditionDetail(query);
      } else {
        // 멀티에디션일 경우
        artworkDetailResult = await this.api.getArtworkDetail(query);
      }

      if (artworkDetailResult.code === 0) {
        this.currentTab = this.editionId > 0 ? 'status' : 'editions';
        this.tabManager(this.editionId);
        // editionInfos는 지금 보고있는 작품 그 자체 정보가 있고
        // 멀티에디션 상황에서 자식에디션들의 정보는 editionItems로 받아온다.

        this.editionInfos = { ...artworkDetailResult.editionInfos };
        this.makingInfos = [...artworkDetailResult.makingInfos];
        this.docentInfo = { ...artworkDetailResult.docentInfo };
        this.totalEdition = artworkDetailResult.editionInfos.totalEdition;
        this.totalCount = artworkDetailResult.editionInfos.totalCount;

        if (this.editionInfos.mimeType === 'video/mp4') {
          // NOTE: Origin Code
          // this.artworkBackground = this.editionInfos.videoThumbInfos[0].resUrl;

          // NOTE: 23-02-14 expose FHD image ( rsrcTp 111 - 1920x1080 ) requested by hyLee (PM)
          let standardOfThumbnailPriority = 111;
          let _videoThumbInfos = this._sortByStandard(
            [...this.editionInfos?.videoThumbInfos],
            standardOfThumbnailPriority,
          );
          this.artworkBackground = _videoThumbInfos[0]?.resUrl;

          // NOTE: Origin Code
          // this.source = this.editionInfos.videoInfos[0].resUrl;

          // NOTE: 23-02-14 expose HD video ( rsrcTp 102 - 1280x720 ) requested by hyLee (PM)
          let standardOfVideoPriority = 102;
          let _videoInfos = this._sortByStandard(
            [...this.editionInfos?.videoInfos],
            standardOfVideoPriority,
          );
          this.source = _videoInfos[0]?.resUrl;

          this.source_medium = this.editionInfos.videoThumbInfos?.[0].resUrl;
          this.source_small = this.editionInfos.videoThumbInfos?.[1].resUrl;
        } else {
          this.artworkBackground = this.source =
            this.editionInfos.imageInfos[0].resUrl;

          if (this.editionInfos.imageInfos[2]) {
            this.source_medium = this.editionInfos.imageInfos[1].resUrl;
            this.source_small = this.editionInfos.imageInfos[2].resUrl;
          } else {
            this.source_medium = this.source;
            this.source_small = this.editionInfos.imageInfos[1].resUrl;
          }
        }

        this.showPriceTax = this.editionInfos.salesMethod != 1 ? true : false;
        this.artistAvatarImg = this.editionInfos.artistInfo?.avatarInfo?.length
          ? this.editionInfos.artistInfo.avatarInfo[0].resUrl
          : null;

        this.artistName = this.editionInfos.artistInfo
          ? this.editionInfos.artistInfo.name
          : '';

        let x = this.editionInfos.contractAddress;
        x = x.substring(x.indexOf('@') + 1);
        this.contractAddress = x;

        if (this.editionInfos.favorites === 'Y') {
          this.likeStatus = true;
        }
        await this.getStatusOrEditions(false, true);

        //this.getRefresh(); //refrehs 기능 비활성 기획협의됨
        //this.tabManager(this.editionId);

        this.currentTab = this.editionId > 0 ? 'status' : 'editions';
      } else {
        this.$router.push('/marketplace');
        return false;
      }
    },
    async getNftBalance() {
      let walletParams = {};

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      //}
      await this.api.getNftBalance(walletParams).then(res => {
        if (res.code === 0) {
          this.accountId = res.bc_account;
          this.usdcBalance = res.usdcBalance;
        }
      });
    },
    async getWalletCheck() {
      this.isLoading = true;

      await this.getNftBalance();

      if (!this.accountId) {
        this.associatedError = true;
        return false;
      }

      let walletParams = {
        accountId: this.accountId,
      };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      //}

      await this.api.getUsdcAssociation(walletParams).then(res => {
        this.isLoading = false;
        if (res.code === 0) {
          this.associatedYn = res.associatedYn;
          this.associatedSign = res.signUrl;
          this.associatedError = false;
          this.walletTokenExpired = false;
          this.walletChange = false;
        } else if (res.code === 1033) {
          this.walletTokenExpired = true;
          this.walletChange = false;
        } else if (res.code === 1034) {
          this.walletTokenExpired = false;
          this.walletChange = true;
        } else {
          this.walletTokenExpired = false;
          this.walletChange = false;
          this.associatedError = true;
        }
      });
    },
    clearTimer() {
      clearInterval(this.polling_refresh);
      clearInterval(this.polling);
    },
    goArtistProfile() {
      this.$router.push({
        name: 'ProfileArtist',
        params: {
          mbrNo: this.editionInfos.artistInfo.userId,
        },
      });
    },
    goUserProfile(otherMarket) {
      if (otherMarket) {
        return false;
      }
      this.$router.push({
        name: 'otherProfile',
        params: {
          mbrNo: this.editionInfos.ownerId,
        },
      });
    },

    purchaseQRdata(item) {
      this.currentQrInfo =
        this.mbrNo != null && this.mbrNo == item.ownerId
          ? this.changeSelling
          : this.purchaseData;

      this.showQrModal = true;
    },
    goContract() {
      if (this.editionInfos.bcNwTp == '20') {
        this.goEth();
      } else {
        this.goHdr();
      }
    },
    goEth() {
      let etherscan =
        process.env.VUE_APP_PROFILE === 'DEV'
          ? 'sepolia.etherscan.io'
          : 'etherscan.io';
      window.open(
        'about:blank',
      ).location.href = `https://${etherscan}/address/${this.contractAddress}`;
    },
    goHdr() {
      let dragonLessUrl =
        process.env.VUE_APP_PROFILE === 'DEV'
          ? 'testnet.dragonglass.me'
          : 'app.dragonglass.me';
      window.open(
        'about:blank',
      ).location.href = `https://${dragonLessUrl}/hedera/search?q=${this.contractAddress}`;
    },
    directUpdateInfo() {
      this.selectEditionId = '';
      this.selectbcNwtp = '';
      this.showInfoEditionPrice = 0;
      this.showInfoEditionPriceToEthUsd = 0;
      this.openModal('UpdateInfoModal');
    },
    async zipcodeSave(editionId) {
      this.zipCodeValidation();
      await this.getMarketplaceArtworkOrEdition();

      if (!editionId) return false;

      let tmpItem = {};
      if (this.editionInfos.editionId == editionId) {
        tmpItem = this.editionInfos;
      } else {
        tmpItem = this.editionItems.find(x => x.editionId == editionId);
      }

      if (
        this.hdrWallet.walletYn === 'N' &&
        this.walletOpt.value === 'WLT002'
      ) {
        this.showWallet = true;
      } else {
        if (this.editionInfos.bcNwTp == '20') {
          await this.purchaseEvt(tmpItem);
        } else {
          if (!this.$isMobile()) {
            this.purchaseQRdata(tmpItem);
          } else {
            await this.purchaseEvt(tmpItem);
          }
        }
      }
    },

    // TODO : resource List sort method commonization ( like mixins )
    _sortByStandard(resourceArray, standardOfPriority) {
      if (resourceArray && resourceArray.length > 0) {
        resourceArray.sort((a, b) => {
          if (
            Math.abs(a.rsrcTp - standardOfPriority) >
            Math.abs(b.rsrcTp - standardOfPriority)
          ) {
            return 1;
          } else if (
            Math.abs(a.rsrcTp - standardOfPriority) <
            Math.abs(b.rsrcTp - standardOfPriority)
          ) {
            return -1;
          } else {
            return a.rsrcTp < b.rsrcTp ? 1 : -1;
          }
        });
      }
      return resourceArray;
    },
    checkOwner(item) {
      if (item.bcNwTp == '20') {
        return (
          // this.mbrNo === item.ownerId &&
          //lislis
          this.accessToken &&
          item?.ownrWlltAcnt?.toLowerCase() ==
            this.getWeb3ModalStatus?.address?.toLowerCase()
        );
      } else {
        return this.mbrNo === item.ownerId;
      }
    },
    ...mapMutations({
      setHdrWallet: 'userStore/hdrWallet',
    }),
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setFixedTab);
    this.clearTimer();
  },
};
</script>
<style scoped>
@import '../../assets/css/detail.css';
.clickable {
  cursor: pointer;
}
.textUnderLine {
  text-decoration: underline;
}

.item {
  position: relative;
}
.item .ic_area {
  position: absolute;
  z-index: 10;
}

.item .ic_area {
  width: 40px;
  height: 40px;
  padding: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.item .ic_area.type_video {
  right: 7px;
  top: 7px;
}
.isDeactive {
  opacity: 0.2;
  pointer-events: none;
}
/* edition list in marketplace detail page at PC, space increase between editionId and owner name td - request by hyLee(Pm) */
.tbl_area .tbl .own {
  padding-left: 40px;
}
.profile_info {
  cursor: default;
}
.ethName {
  cursor: default;
}
.tbl.isLoading {
  display: flex;
  flex-flow: column;
}
</style>
