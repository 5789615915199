<template>
  <main id="contents" class="my_list_page wallet_page">
    <div class="set_inner clearFix">
      <h1 class="page_ttl only_pc">My Account</h1>
      <h1 class="page_ttl dv_tab">My Wallet</h1>
      <AccountMenu />
      <div class="min_inner">
        <!-- PUB : 신규 UI 지갑 연결 후 S -->
        <!-- 지갑 연결 후-->
        <section class="wallet_sec">
          <!-- WIP : userStore 값으로-->
          <div
            class="wallet_title"
            v-if="ethInfo.walletYn === 'Y' || hdrInfo.walletYn === 'Y'"
          >
            <h2>My Wallet</h2>
            <p>
              <em class="c_point">Manage your wallet</em> accounts and check
              balances.
            </p>
          </div>
          <div class="wallet_connect_title" v-else>
            <h2>Connect Your Wallet</h2>
            <p>
              Connect a wallet to purchase
              <em class="c_point">NFTs from LG Art Lab&nbsp;</em
              ><br class="dv_pc" />Choose and press the button you’d like to
              connect a wallet.
            </p>
          </div>

          <!-- ethereum 연결 후 -->
          <div class="wallet_item_wrap" v-if="ethInfo.walletYn == 'Y'">
            <div class="wallet_item_head">
              <div class="wallet_info_group">
                <div class="img_area">
                  <picture>
                    <img
                      :src="require('@/assets/img/img_wallet_eth.svg')"
                      alt="ethereumWallet"
                    />
                  </picture>
                </div>
                <div class="name_area">Ethereum</div>
                <div class="btn_area">
                  <button
                    @click="callDisconnect(ethInfo.bcNwTp)"
                    class="btn h_m strong disconnect"
                    :class="{
                      is_loading: loading_eth,
                    }"
                  >
                    Disconnect
                  </button>
                </div>
              </div>
            </div>
            <div class="wallet_item_body">
              <div class="money_area">
                <dl>
                  <dt>Balance</dt>
                  <dd>
                    <!-- 소수점 3자리까지 나오도록 -->
                    {{ ethereumBalance }}
                    <span class="unit">ETH</span>
                  </dd>
                </dl>
              </div>
              <div class="account_area">
                <dl>
                  <dt>Account</dt>
                  <dd>{{ addrEllipsis(ethInfo.bc_account) }}</dd>
                </dl>
              </div>
            </div>
          </div>
          <!-- ethereum 연결 전 -->
          <div class="wallet_item_wrap" v-else>
            <div class="wallet_list_group" v-if="loading_eth">
              <LoadingSpinner />
            </div>
            <div class="wallet_list_group" v-else>
              <div class="img_area">
                <picture>
                  <img
                    :src="require('@/assets/img/img_wallet_eth.svg')"
                    alt="ethereumWallet"
                  />
                </picture>
              </div>
              <div class="name_area">
                <p>Ethereum</p>
                <span
                  >Connect your Ethereum wallet to use LG Art Lab service</span
                >
              </div>
              <div class="btn_area">
                <button class="btn h_l strong connect" @click="open('eth')">
                  Connect Wallet
                </button>
              </div>
            </div>
          </div>

          <!-- hedera -->
          <div class="wallet_item_wrap" v-if="hdrInfo.walletYn == 'Y'">
            <div class="wallet_item_head">
              <div class="wallet_info_group">
                <div class="img_area">
                  <picture>
                    <img
                      width="70"
                      height="76"
                      :src="require('@/assets/img/img_wallet_hedera.svg')"
                      alt="hederaWallet"
                    />
                  </picture>
                </div>
                <div class="name_area">
                  <p>Hedera</p>
                </div>
                <div class="btn_area">
                  <button
                    @click="callDisconnect(hdrInfo.bcNwTp)"
                    class="btn h_m strong disconnect"
                    :class="{
                      is_loading: loading_hdr,
                    }"
                  >
                    Disconnect
                  </button>
                </div>
              </div>
            </div>
            <div class="wallet_item_body">
              <div class="money_area">
                <dl>
                  <dt>Balance</dt>
                  <dd v-if="usdcBalance">
                    {{ usdcBalance }}
                    <span class="unit">USDC</span>
                  </dd>
                  <dd v-else>
                    <Skeleton />
                  </dd>
                </dl>
              </div>
              <div class="account_area">
                <dl>
                  <dt>Account</dt>
                  <dd>{{ hdrInfo.bc_account }}</dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="wallet_item_wrap" v-else>
            <div class="wallet_list_group" v-if="loading_hdr">
              <LoadingSpinner />
            </div>
            <div class="wallet_list_group" v-else>
              <div class="img_area">
                <picture>
                  <img
                    :src="require('@/assets/img/img_wallet_hedera.svg')"
                    alt="hederaWallet"
                  />
                </picture>
              </div>
              <div class="name_area">
                <p>Hedera</p>
                <span
                  >Connect your Hedera wallet to use LG Art Lab service</span
                >
              </div>
              <div class="btn_area">
                <button class="btn h_l strong connect" @click="open('hedera')">
                  Connect Wallet
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <WalletQrModal
      :showWallet="showWallet"
      :modal-data="ChangeModal"
      @close="close"
    />
    <ChangeWalletModal :showChangeWallet="showChangeWallet" @close="close" />
  </main>
</template>

<script>
//import QrcodeVue from 'qrcode.vue';
import AccountMenu from '@/components/common/AccountMenu';
import Skeleton from '../../components/common/Skeleton.vue';
import WalletQrModal from '@/components/mylist/WalletQrModal';
import ChangeWalletModal from '@/components/mylist/ChangeWalletModal';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'WalletView',
  components: {
    WalletQrModal,
    ChangeWalletModal,
    //QrcodeVue,
    AccountMenu,
    Skeleton,
    LoadingSpinner,
  },
  data() {
    return {
      ethInfo: {},
      hdrInfo: {},
      ChangeModal: {
        title: 'Change Accounts',
        desc:
          'Change your account or connect to a different wallet to purchase.\n' +
          'Use your mobile phone to scan the QR code.',
      },
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
      disconnectAlert: {
        type: 'warn',
        desc: 'Fail Disconnect wallet...',
      },
      showWallet: false,
      showChangeWallet: false,
      polling: null,
      overtimer: 180000,
      loading_eth: false,
      loading_hdr: false,
      hdrConnType: '',
      qrcodeSize: 290,
    };
  },
  computed: {
    ...mapGetters({
      ethWallet: 'userStore/ethWallet',
      hdrWallet: 'userStore/hdrWallet',
      email: 'userStore/email',
      getWeb3ModalStatus: 'web3Store/getWeb3ModalStatus',
    }),
    ethereumBalance() {
      return parseFloat(this.ethInfo.balance).toFixed(3);
    },
    usdcBalance() {
      return parseFloat(this.hdrInfo.usdcBalance).toFixed(2);
    },
    qrcodeUrl() {
      return window.location.origin + '/mylist/user?redirect=true';
    },
    openBlade() {
      return JSON.parse(process.env.VUE_APP_OPEN_BLADE) ? true : false;
    },
    openWallypto() {
      return JSON.parse(process.env.VUE_APP_OPEN_WALLYPTO) ? true : false;
    },
  },
  watch: {
    async getWeb3ModalStatus(v) {
      if (v.address) {
        await this.connectETH(v.address);
      }
    },
  },
  mounted() {
    //QR Code 웹접근성 처리
    const canvasElements = document.querySelectorAll('canvas');

    canvasElements.forEach(canvas => {
      canvas.setAttribute('aria-hidden', 'true');
    });

    //지갑정보가 없으면 disconnect 0624
    if (!this.getWeb3ModalStatus.address) {
      this.callDisconnect('20');
    }

    // 지갑이 하나라도 연결되어있으면 호출
    if (this.ethWallet.walletYn == 'Y' || this.hdrWallet.walletYn == 'Y') {
      this.getNftBalance();
    }
  },
  methods: {
    addrEllipsis(addr) {
      return addr.length > 15
        ? addr.substr(0, 8) + '...' + addr.substr(addr.length - 3, addr.length)
        : addr;
    },
    async connectETH(address) {
      let walletParams = {
        bcNwTp: '20',
        walletAddr: address,
        wlltType: 'WLT003',
      };
      await this.api.connectWallet(walletParams);
      await this.getNftBalance('eth');
    },
    open(wallet) {
      if (wallet === 'eth') {
        this.W3M.open();
        return false;
      }
      if (this.$isMobile()) {
        if (this.loading_hdr) return false;
        this.showChangeWallet = true;
      } else {
        this.showWallet = true;
      }
    },
    close(type) {
      this.showWallet = false;
      this.showChangeWallet = false;
      if (type) {
        this.hdrConnType = type;
        this.getHederaConnectWallet();
      } else {
        clearInterval(this.polling);
        this.hdrConnType = '';
        this.getNftBalance('hdr');
      }
    },
    async getHederaConnectWallet() {
      if (this.loading_hdr) return false;
      this.loading_hdr = true;
      clearInterval(this.polling);

      let walletParams = {
        wlltType: this.hdrConnType,
      };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //히스토리를 몰라서 주석처리합니다. 240715 - kwk
      // if (process.env.VUE_APP_PROFILE === 'DEV') {
      //   walletParams.bcNwTp = '10';
      // }
      walletParams.bcNwTp = '10';

      await this.api.connectWallet(walletParams).then(res => {
        if (res.code === 0) {
          if (this.hdrConnType === 'WLT001') {
            let params = {
              hostServer: res.hostServer,
              signAddr: res.signAddr,
              scheme: 'setup',
              price: -1,
            };
            this.onBlade(params, this.email);
          } else if (this.hdrConnType === 'WLT002') {
            this.onCallApp(res.signUrl);
          } else {
            this.$router.push({
              name: 'network',
            });
          }

          setTimeout(() => {
            this.loading_hdr = false;
          }, 10000);

          this.polling = setInterval(() => {
            this.getProfile();
            if (this.overtimer <= 0) {
              this.loading_hdr = false;
              clearInterval(this.polling);
            }
            this.overtimer -= 3000;
          }, 3000);
        } else {
          this.loading_hdr = false;
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    async getProfile() {
      await this.api.getProfile({}).then(res => {
        if (res.code === 0) {
          let hdrWalletYn = res.walletInfos
            ? res.walletInfos.find(v => v.bcNwTp === '10').walletYn
            : {};

          if (hdrWalletYn == 'Y') {
            this.loading_hdr = false;
            clearInterval(this.polling);
            this.getNftBalance('hdr');
          }
        } else {
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    async getNftBalance(type) {
      let walletParams = {};

      if (type == 'eth') {
        this.loading_eth = true;
      } else if (type == 'hdr') {
        this.loading_hdr = true;
      } else {
        this.loading_hdr = true;
        this.loading_eth = true;
      }
      await this.api.getNftBalance(walletParams).then(res => {
        if (res.code === 0) {
          this.loading_hdr = false;
          this.loading_eth = false;
          this.setWalletInfo(res.walletBalances.find(v => v.bcNwTp === '10'));
          this.setWalletInfo(res.walletBalances.find(v => v.bcNwTp === '20'));
          // NOTE : 0509 AS-IS : 기존 Hdedra 토큰 만료 시 1037 코드로 WalletYn 상태 front에서 walletYn 수동변경 -> 지갑연결 화면 다시 노출
          // NOTE : TO-BE : API 협의 1037발생하는 케이스를 1037 제거 정상이면서 hedera WalletYn N API수정
        } else {
          this.loading_hdr = false;
          this.loading_eth = false;
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    setWalletInfo(obj) {
      if (!obj) return false;

      let coins = {
        10: 'hdrInfo',
        20: 'ethInfo',
      };

      this[coins[obj.bcNwTp]] = {
        ...obj,
      };
    },
    async callDisconnect(bcNwTp) {
      if (!bcNwTp) return false;

      let res = await this.api.disconnectWallet({ bcNwTp });
      if (res.code === 0) {
        if (bcNwTp == 20) {
          this.W3M.disconnect();
        }
        this.getNftBalance();
      } else {
        this.$root.$emit('openAlert', this.disconnectAlert);
      }
    },
    ...mapMutations({
      setEthWallet: 'userStore/ethWallet',
      setHdrWallet: 'userStore/hdrWallet',
    }),
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>

<!-- PUB : 신규 UI 관련 CSS 수정 -->
<style scoped>
/* .wallet_page .wallet_item_wrap .wallet_item_head {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid var(--line-color);
} */
.unit {
  font-weight: 400;
}
.is_loading {
  background: #e9ecef !important;
  color: black !important;
}
@media screen and (max-width: 1024px) {
  .usdc {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .usdc {
    font-size: 1.6rem;
  }
}
.qr-title {
  margin-top: 0px !important;
}
.qr-area {
  max-width: 310px;
  margin: 36px auto;
}
.qr-code {
  background-color: #fff;
  padding: 10px;
}

.connect {
  min-width: 30rem;
}

.disconnect {
  min-width: 13rem;
  background: transparent;
  color: #939393;
  border: 1px solid #444;
}

@media screen and (max-width: 767px) {
  .connect {
    min-width: 15.5rem;
  }
}
@media screen and (max-width: 600px) {
  .change {
    margin: 0px 20px 10px 20px;
  }
  .disconnect {
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 320px) {
  .wallet_sec .guide_ui p br {
    display: none;
  }
}
</style>
